import { UUID } from 'crypto';

export type EmailTemplateResponse = {
  createDate: string;
  updateDate: string;
  ownerId: number;
  creatorId: number;
  uuid: UUID;
  name: string;
  subject: string;
  body: string;
  fromUserId: string;
};

export type MondayBoardColumn = {
  id: string;
  title: string;
  type: string;
};

export type MondayBoardColumnsResponse = {
  boards: MondayColumnsResponse[];
};

export type MondayColumnsResponse = {
  name: string;
  columns: MondayBoardColumn[];
};

export type MondayUsersInWorkspaceResponse = {
  users: MondayUserProfileResponse[];
};

export type MondayWorkplaceUsersResponse = {
  users_subscribers: MondayUserProfileResponse[];
};

export type MondayUserProfileResponse = {
  name: string;
  id: string;
  photo_thumb: string;
};

export type DomainResponse = {
  domain: string;
};

export type DomainValidationResponse = {
  valid: boolean;
  validationResults: Record<string, DnsRecordValidationResponse>;
};

export type DnsRecordValidationResponse = {
  valid: boolean;
  reason: string;
};

export type DomainAuthenticationStatusResponse = {
  id: number;
  domain: string;
  subdomain: string;
  valid: boolean;
  dns: Record<string, DnsRecord>;
};

export type DnsRecord = {
  valid: boolean;
  type: string;
  host: string;
  data: string;
};

export type MondayCurrentUser = {
  me: MondayUser;
};

export type MondayUser = {
  email: string;
};

export enum AuthenticationStatus {
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
}

export type DeclaredDomain = {
  domain: string;
  authenticationStatus: AuthenticationStatus;
};

export type UserContext = {
  userId: string;
  accountId: string;
  boardId: number;
  workspaceId: number;
  isViewOnly: boolean;
  isGuest: boolean;
  isAdmin: boolean;
};

export enum PricingPlan {
  FREE = 'FREE',
  LITE = 'LITE',
  ESSENTIAL = 'ESSENTIAL',
  PRO = 'PRO',
  PREMIUM = 'PREMIUM',
}

export type Usage = {
  plan: PricingPlan;
  quota: number;
  used: number;
  renewalDate: Date;
};

export type MessageResponse = {
  isError: boolean;
  message: string;
};