import { createRoot } from "react-dom/client";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { APIConfig } from "./api/ApiConfig";
import App from "./App";

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

const root = createRoot(rootElement);

const getAPIUrl = () => {
    if (!process.env.REACT_APP_API_URL) {
        throw new Error("REACT_APP_API_URL is not defined");
    }
    return process.env.REACT_APP_API_URL;
};

APIConfig.BASE = getAPIUrl();

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
