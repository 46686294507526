import React, { useState } from "react";
import "./App.css";
import "monday-ui-react-core/dist/main.css";
import { APIConfig } from "./api/ApiConfig";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import UserContextProvider from "./providers/userContextProvider";
import { UserContext } from "./api/ApiTypes";
import mondaySdk from "monday-sdk-js";
import Root from "./routes/root";
import Settings from "./routes/settings";



const App = () => {
  const [userContext, setUserContext] = useState<UserContext | null>(null);

  mondaySdk().listen('context', (res: any) => {
    setUserContext({
      userId: res.data.user.id,
      accountId: res.data.account.id,
      boardId: res.data.boardId,
      workspaceId: res.data.workspaceId,
      isViewOnly: res.data.user.isViewOnly,
      isGuest: res.data.user.isGuest,
      isAdmin: res.data.user.isAdmin,
    });
  });

  const router = createBrowserRouter([
    {
      path: "/",
      element: <UserContextProvider.Provider value={userContext}><Root /></UserContextProvider.Provider>
    }, {
      path: "/settings",
      element: <UserContextProvider.Provider value={userContext}><Settings /></UserContextProvider.Provider>,
    }
  ]);


  return (
    <RouterProvider router={router} />
  );
};


export const getAPIUrl = () => {
  if (!process.env.REACT_APP_API_URL) {
    throw new Error("REACT_APP_API_URL is not defined");
  }
  return process.env.REACT_APP_API_URL;
};

APIConfig.BASE = getAPIUrl();

export default App;
