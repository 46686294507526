import { useCallback, useContext, useEffect, useRef, useState } from "react";
import "monday-ui-react-core/dist/main.css";
import { Popover } from '@headlessui/react'
// import { Menu, Popover, Transition } from '@headlessui/react'
// import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { QuestionMarkCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import EmailTemplateListItem from "@/components/emailTemplateListItem";
import { UUID } from "crypto";
import { Editor } from "@tinymce/tinymce-react";
import { Button, Tipseen, TipseenWizard, Dropdown } from "monday-ui-react-core";
import { APIConfig } from "@/api/ApiConfig";
import { AuthenticationStatus, EmailTemplateResponse, MondayBoardColumn, MondayBoardColumnsResponse, MondayColumnsResponse, MondayUserProfileResponse, MondayUsersInWorkspaceResponse, MondayWorkplaceUsersResponse, UserContext } from "@/api/ApiTypes";
import { useMondaySdk } from "@/hooks/useMondaySdk";
import { Link } from "react-router-dom";
import { mondayGetCurrentUserEmail, sendTestEmail, getDeclaredDomain } from "@/api/ApiCalls";
import UserContextProvider from "@/providers/userContextProvider";
import { Helmet } from "react-helmet";



export type EmailTemplate = {
    uuid?: UUID;
    name: string;
    subject: string;
    body: string;
    from: string; //AKA user id
};

export type DropdownItem = {
    value: string; //user id
    label: string;
    leftAvatar: string;
};


export default function HtmlEmailInterface() {
    const userContext = useContext(UserContextProvider);
    const monday = useMondaySdk();

    const [templates, setTemplates] = useState<EmailTemplate[]>([]);
    const [activeTemplateDraft, setActiveTemplateDraft] = useState<EmailTemplate>();
    const [boardColumns, setBoardColumns] = useState<MondayBoardColumn[]>([]);
    const editorRef = useRef<Editor>();
    const activeInputFieldRef = useRef<any>();
    const [saving, setSaving] = useState<boolean>(false);
    const [showPlaceholdersTip, setShowPlaceholdersTip] = useState<boolean>(false);
    const [workspaceUsers, setWorkspaceUsers] = useState<DropdownItem[]>([]);
    const [placeholdersTipIndex, setPlaceholdersTipIndex] = useState(0);
    const [promptForDomainAuth, setPromptForDomainAuth] = useState(false);
    const [sendingATestEmail, setSendingATestEmail] = useState(false);

    const getTemplates = async () => {
        fetch(`${APIConfig.BASE}/account/${userContext?.accountId}/board/${userContext?.boardId}/template`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': (await monday.get('sessionToken')).data
            }
        })
            .then((response) => {
                if (response.status !== 200) {
                    monday.execute("notice", {
                        message: "Problem getting template. Please retry.",
                        type: "error",
                        timeout: 1500,
                    });
                    throw Error("Problem getting template: " + response.status);

                }
                return response.json();
            }).then((data: EmailTemplateResponse[]) => {
                if (data.length !== 0) {
                    setTemplates(data.map((data) => {
                        return {
                            uuid: data.uuid,
                            name: data.name,
                            subject: data.subject,
                            body: data.body,
                            from: data.fromUserId
                        }
                    }));
                }
            });
    };

    const getWorkspaceUsers = async () => {
        monday.api(`query {users {name, id, photo_thumb}}`)
            .then((reponseBody) => {
                const data = reponseBody.data as MondayUsersInWorkspaceResponse;

                if (data.users.length === 0) {
                    throw Error("Problem getting board users, got none!");
                }
                return data.users;
            }).then((users: MondayUserProfileResponse[]) => {
                const people: DropdownItem[] = users.map((profile: MondayUserProfileResponse) => { return { label: profile.name, value: profile.id + "", leftAvatar: profile.photo_thumb } });
                setWorkspaceUsers(people.sort((a, b) => a.label.localeCompare(b.label)));
            });

    };

    const getBoardColumns = async () => {
        const boardIds = `[${userContext?.boardId}]`;

        monday.api(`query {boards(ids: ${boardIds}) { name, columns {id, title, type}}}`)
            .then((data) => {
                const boardData = data.data as MondayBoardColumnsResponse;

                if (boardData.boards.length !== 1) {
                    throw Error("Problem getting board columns: " + boardData.boards.length);
                }
                return boardData.boards[0];
            }).then((data: MondayColumnsResponse) => {
                const addressableColumns: MondayBoardColumn[] = [{ id: "board.name", title: "Board Name", type: "text" }];

                for (let i = 0; i < data.columns.length; ++i) {
                    data.columns[i].id = "pulse." + data.columns[i].id;
                }
                const ignoredColumnTypes = ["board-relation", "mirror", "lookup", "button", "color_picker", "dependency", "file", "integration", "subtasks", "unsupported"];
                setBoardColumns(addressableColumns.concat(data.columns).filter(c => !ignoredColumnTypes.includes(c.type)));
            });
    };


    useEffect(() => {
        if (!userContext || userContext.accountId === undefined || userContext.isViewOnly || userContext.isGuest) {
            return;
        }
        getDeclaredDomain(monday, userContext.accountId).then((res) => {
            setPromptForDomainAuth(res.authenticationStatus !== AuthenticationStatus.VERIFIED);
        });
        getWorkspaceUsers();
        getTemplates();
        getBoardColumns();

    }, [userContext?.accountId]);


    const onChangePlaceholderExplainStep = useCallback((_e: any, stepIndex: number) => {
        setPlaceholdersTipIndex(stepIndex);
    }, []);

    const findMatchingUserForDraft = () => {
        const user = workspaceUsers.filter(u => { return u.value === (activeTemplateDraft ? activeTemplateDraft.from : "-1") })[0];
        if (user) {
            return user;
        }
        return { label: "", title: "", leftAvatar: "" };
    }


    const createTemplate = async (template: EmailTemplate) => {
        setSaving(true);
        fetch(`${APIConfig.BASE}/account/${userContext?.accountId}/board/${userContext?.boardId}/template`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': (await monday.get('sessionToken')).data
            },
            body: JSON.stringify({
                name: template.name,
                subject: template.subject,
                body: template.body,
                fromUserId: template.from
            })
        })
            .then((response) => {
                setSaving(false);
                if (response.status !== 200) {
                    monday.execute("notice", {
                        message: "Problem creating template. Please retry.",
                        type: "error",
                        timeout: 1500,
                    });
                    throw Error("Problem creating template: " + response.status);
                }
                monday.execute("notice", {
                    message: "Template Saved!",
                    type: "success",
                    timeout: 1500,
                });
                return response.json();
            }).then((data: EmailTemplateResponse) => {
                const newTemplate: EmailTemplate = {
                    uuid: data.uuid,
                    name: data.name,
                    subject: data.subject,
                    body: data.body,
                    from: data.fromUserId
                };
                setTemplates([newTemplate, ...templates]);
                setActiveTemplateDraft({ ...newTemplate })
            });
    };

    const updateTemplate = async (template: EmailTemplate) => {
        setSaving(true);
        fetch(`${APIConfig.BASE}/account/${userContext?.accountId}/board/${userContext?.boardId}/template/${template.uuid}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': (await monday.get('sessionToken')).data
            },
            body: JSON.stringify({
                uuid: template.uuid,
                name: template.name,
                subject: template.subject,
                body: template.body,
                fromUserId: template.from
            })
        })
            .then((response) => {
                setSaving(false);
                if (response.status !== 200) {
                    monday.execute("notice", {
                        message: "Error creating template. Please retry.",
                        type: "error",
                        timeout: 1500,
                    });
                    throw Error("Problem creating template: " + response.status);
                }
                monday.execute("notice", {
                    message: "Template Saved!",
                    type: "success",
                    timeout: 1500,
                });
                return response.json();
            }).then((data: EmailTemplateResponse) => {
                const updatedTemplate: EmailTemplate = {
                    uuid: data.uuid,
                    name: data.name,
                    subject: data.subject,
                    body: data.body,
                    from: data.fromUserId
                };

                // in place update the template where it is in the list of other templates
                for (let i = 0; i < templates.length; ++i) {
                    if (templates[i].uuid === updatedTemplate.uuid) {
                        templates[i] = updatedTemplate;
                        break;
                    }
                }
                setTemplates([...templates]);
                setActiveTemplateDraft({ ...updatedTemplate });
            });
    };


    // TODO fix these types. importing types from modules is so confusing!!!
    const uploadHandler = async (blobInfo: any, progress: any): Promise<string> => {
        // straight copy + paste from https://www.tiny.cloud/docs/tinymce/6/file-image-upload/#example-using-images_upload_handler
        const sessionToken = (await monday.get('sessionToken')).data;

        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.withCredentials = false;
            xhr.open('POST', `${APIConfig.BASE}/account/${userContext?.accountId}/board/${userContext?.boardId}/template/${activeTemplateDraft?.uuid}/image`);
            xhr.setRequestHeader('Authorization', sessionToken);

            xhr.upload.onprogress = (e) => {
                progress(e.loaded / e.total * 100);
            };

            xhr.onload = () => {
                if (xhr.status === 403) {
                    reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
                    return;
                }

                if (xhr.status < 200 || xhr.status >= 300) {
                    reject('HTTP Error: ' + xhr.status);
                    monday.execute("notice", {
                        message: "There was an issue uploading your image. Please try again!",
                        type: "error",
                        timeout: 1500,
                    });
                    return;
                }

                const json = JSON.parse(xhr.responseText);

                if (!json || typeof json.location != 'string') {
                    reject('Invalid JSON: ' + xhr.responseText);
                    return;
                }

                resolve(json.location);
            };

            xhr.onerror = () => {
                monday.execute("notice", {
                    message: "There was an issue uploading your image. Please try again!",
                    type: "error",
                    timeout: 1500,
                });
                reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
            };

            const formData = new FormData();
            formData.append('file', blobInfo.blob(), blobInfo.filename());

            xhr.send(formData);
        })
    };


    const validateInput = () => {
        if (!activeTemplateDraft?.name) {
            monday.execute("notice", {
                message: "Template name cannot be empty.",
                type: "error",
                timeout: 1500,
            });
            throw new Error("Template name cannot be empty.");
        }

        if (!activeTemplateDraft?.from) {
            monday.execute("notice", {
                message: "From cannot be empty.",
                type: "error",
                timeout: 1500,
            });
            throw new Error("From name cannot be empty.");
        }

        if (!activeTemplateDraft?.subject) {
            monday.execute("notice", {
                message: "Subject cannot be empty.",
                type: "error",
                timeout: 1500,
            });
            throw new Error("Subject cannot be empty.");
        }

        if (!activeTemplateDraft?.body) {
            monday.execute("notice", {
                message: "Body cannot be empty.",
                type: "error",
                timeout: 1500,
            });
            throw new Error("Body cannot be empty.");
        }

        // TODO validate body is valid HTML
        // TODO highlight the field in red or something
    };

    const saveTemplate = () => {
        // Notice this method notifies the monday platform that user gains a first value in an app.
        // Read more about it here: https://developer.monday.com/apps/docs/mondayexecute#value-created-for-user/
        monday.execute("valueCreatedForUser");

        if (!activeTemplateDraft) {
            throw new Error('no active template draft');
        }
        if (editorRef.current) {
            activeTemplateDraft.body = editorRef.current.getContent();
        }

        validateInput();

        if (!activeTemplateDraft.uuid) {
            createTemplate(activeTemplateDraft);
        } else {
            updateTemplate(activeTemplateDraft);
        }
    };

    const createDraft = (uuid: UUID) => {
        const toDraft = templates.find(t => t.uuid === uuid)
        if (!toDraft) {
            throw new Error('no template found to create draft from');
        }
        setActiveTemplateDraft({ ...toDraft });

        // Notice this method notifies the monday platform that user gains a first value in an app.
        // Read more about it here: https://developer.monday.com/apps/docs/mondayexecute#value-created-for-user/
        monday.execute("valueCreatedForUser");
    };


    // come back to this- interesting typscript issue
    // type EmailTemplateFields = EmailTemplate[keyof EmailTemplate];
    // const onTemplateChange = (fieldName: EmailTemplateFields, value: string) => {
    //     if (!activeTemplateDraft) {
    //         console.log('no active template draft');
    //         return;
    //     }
    //     if (fieldName === null || fieldName == undefined) {
    //         return;
    //     }

    //     activeTemplateDraft[fieldName] = value;
    // };

    const onSubjectChange = (value: string) => {
        if (!activeTemplateDraft) {
            throw new Error('no active template draft');
        }
        activeTemplateDraft.subject = value;
        setActiveTemplateDraft({ ...activeTemplateDraft });
    };

    const onNameChange = (value: string) => {
        if (!activeTemplateDraft) {
            throw new Error('no active template draft');
        }
        activeTemplateDraft.name = value;
        setActiveTemplateDraft({ ...activeTemplateDraft });
    };

    const onFromChange = (item: DropdownItem) => {
        if (!activeTemplateDraft) {
            throw new Error('no active template draft');
        }
        activeTemplateDraft.from = item.value;
        setActiveTemplateDraft({ ...activeTemplateDraft });
    };

    const asString = (value: string | undefined): string => {
        if (!value) {
            return "";
        }
        return value;
    }

    const addAtEditorCursor = (value: string) => {
        // TODO ref would be a better way to do this, but whatever
        if (activeInputFieldRef.current?.id === "Subject") {
            // TODO use cursor location
            // https://stackoverflow.com/questions/34922331/getting-and-setting-cursor-position-of-uitextfield-and-uitextview-in-swift
            onSubjectChange(asString(activeTemplateDraft?.subject) + value);
        } if (activeInputFieldRef.current?.id === "Editor") {
            editorRef.current.execCommand('mceInsertContent', false, value);
        }
    }

    const accessDeniedView = (userContext: UserContext) => {
        let userType = "read only";
        if (userContext.isGuest) {
            userType = "guest";
        }

        return <div className="bg-gray-50 sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
                <div className="flex flex-row gap-2">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    <h3 className="text-base font-semibold leading-6 text-gray-900">As a {userType} user, you don&apos;t have access to view or edit email templates.</h3>
                </div>
                <div className="mt-2 max-w-xl text-sm text-gray-500">
                    <p>Only users with write access to this board can work with email templates. Get permission from the board owner to continue.</p>
                </div>
            </div>
        </div>;
    };

    const sendMeATestEmail = (accountId: string | undefined, boardId: number | undefined, activeTemplateDraft: EmailTemplate) => {
        setSendingATestEmail(true);
        mondayGetCurrentUserEmail(monday).then((userEmail) => {
            sendTestEmail(accountId, boardId, activeTemplateDraft, userEmail, monday).then((response) => {
                setSendingATestEmail(false);
                if (response.isError) {
                    monday.execute("notice", {
                        message: "Problem sending test email. Please retry or contact support.",
                        type: "error",
                        timeout: 1500,
                    });
                    throw Error("Problem sending test email: " + response.message);
                }
                monday.execute("notice", {
                    message: "Test email sent! Check your inbox.",
                    type: "success",
                    timeout: 1500,
                });
            });
        });
    }

    const emailTemplatesView = () => {
        return <>
            <h1 className="sr-only">Email Wonderful</h1>
            {/* Main 3 column grid */}
            <div className="grow flex flex-col overflow-auto">
                <div className="grow flex flex-col grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8 overflow-auto">

                    {/* Left column */}
                    <div className="grow flex flex-col grid grid-cols-1 gap-4">
                        <section className="grow flex flex-col" aria-labelledby="section-1-title" >
                            <h2 className="grow-0 sr-only" id="section-1-title">
                                Templates
                            </h2>
                            <div className="grow flex flex-col rounded-lg bg-white" style={{ height: "90vh" }}>
                                {promptForDomainAuth &&
                                    <div className="">
                                        <div className="shrink-0 flex flex-col rounded-lg bg-white -mb-4 p-2">
                                            <div className="rounded-md bg-yellow-50 p-4">
                                                <div className="flex">
                                                    <div className="flex-shrink-0">
                                                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                                    </div>
                                                    <div className="ml-3">
                                                        <div className="text-sm text-yellow-700">
                                                            <p className="text-balance">
                                                                Setup is needed to start sending emails! You can do that on the <Link to={'/settings?section=sendingconfiguration'}>
                                                                    <span className="text-blue-500 underline">
                                                                        Sending Configuration
                                                                    </span>
                                                                </Link>{' '}
                                                                page under Admin Settings.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                <div className="grow flex flex-col space-y-3 p-6 overflow-auto">
                                    <Button color={Button.colors.POSITIVE} onClick={() => {
                                        setActiveTemplateDraft({ name: '', subject: '', body: '', from: userContext ? userContext.userId : "-1" });
                                    }}>Create a new template</Button>
                                    {<Link className="flex flex-row" to={`/settings`}><Button className="grow flex flex-row" color={Button.colors.PRIMARY}>Admin Settings</Button></Link>}
                                    {templates.map((template, i) => {
                                        return (<EmailTemplateListItem
                                            key={`EmailTemplateListItem-${i}`}
                                            template={template}
                                            isActive={template.uuid === activeTemplateDraft?.uuid}
                                            onSelection={() => createDraft(template.uuid as UUID)}
                                        />);
                                    })}
                                </div>
                            </div>
                        </section>
                    </div>

                    {/* Right column */}
                    <div className="grow flex flex-col  grid grid-cols-1 gap-4 lg:col-span-2">
                        <section className="grow flex flex-col" aria-labelledby="section-2-title">
                            <h2 className="sr-only" id="section-2-title">
                                Email Builder
                            </h2>
                            {activeTemplateDraft &&
                                <div className="grow flex flex-col justify-start rounded-lg bg-white shadow overflow-auto" style={{ height: "90vh" }}>
                                    <div className="flex flex-col p-6 h-min-96">
                                        <div className="">
                                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-6">
                                                <div className="col-span-full">
                                                    <label htmlFor="TemplateName" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Template Name
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            id="TemplateName"
                                                            name="TemplateName"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            value={activeTemplateDraft.name}
                                                            onChange={(newValue) => onNameChange(newValue.target.value)}
                                                            onFocus={(t) => activeInputFieldRef.current = t.target}
                                                            autoComplete="off"
                                                            data-form-type="other"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-span-full">
                                                    <div className="flex flex-row grow-0 items-center">
                                                        <label htmlFor="From" className="block text-sm font-medium leading-6 text-gray-900 mr-1">
                                                            From
                                                        </label>
                                                    </div>
                                                    <div className="mt-2">

                                                        <Dropdown
                                                            clearable={false}
                                                            onFocus={(t: any) => activeInputFieldRef.current = t.target}
                                                            onChange={(i: DropdownItem) => { onFromChange(i); }}
                                                            value={findMatchingUserForDraft()}
                                                            options={[findMatchingUserForDraft()]}
                                                            className="dropdown-stories-styles_with-chips tinymce_toolbar_hack" />
                                                    </div>

                                                </div>

                                                <div className="col-span-full">
                                                    <label htmlFor="Subject" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Subject
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            id="Subject"
                                                            name="Subject"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            value={activeTemplateDraft.subject}
                                                            onChange={(newValue) => onSubjectChange(newValue.target.value)}
                                                            onFocus={(t) => activeInputFieldRef.current = t.target}
                                                            autoComplete="off"
                                                            data-form-type="other"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-span-full">
                                                    <Editor
                                                        id="Editor"
                                                        apiKey="81m3hqt9fgm6nw29j478jczt76fmjfiza1zgsq195kxqu8ol"
                                                        onInit={(evt, editor) => editorRef.current = editor}
                                                        initialValue={activeTemplateDraft.body}
                                                        onFocus={(t) => activeInputFieldRef.current = t.target}
                                                        init={{
                                                            readonly: saving || userContext?.userId != activeTemplateDraft.from,
                                                            encoding: 'xml',
                                                            placeholder: "An email from {board.name}",
                                                            menubar: "file edit format",
                                                            // advcode_inline: true,
                                                            plugins: [
                                                                'link', 'code', 'lists', 'image'
                                                            ],
                                                            toolbar:
                                                                'undo redo fontfamily blocks code |' +
                                                                'bold italic underline forecolor backcolor link image removeformat |' +
                                                                'alignleft aligncenter alignright alignjustify | ' +
                                                                'bullist numlist outdent indent bullist numlist',
                                                            toolbar_mode: 'wrap',
                                                            valid_children: '+body[style]',
                                                            visual: false,
                                                            file_picker_types: 'image',
                                                            image_description: false,
                                                            image_dimensions: false,
                                                            image_title: true,
                                                            image_source: false,
                                                            images_upload_credentials: true,
                                                            content_css: '/skins/content/emailwonderful/content.min.css',

                                                            automatic_uploads: true,
                                                            images_reuse_filename: true,
                                                            images_upload_handler: uploadHandler,
                                                            quickbars_image_toolbar: true,
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-full flex flex-col">
                                                    <Button type={Button.types.BUTTON} size={Button.sizes.SMALL} disabled={saving} onClick={() => { saveTemplate(); sendMeATestEmail(userContext?.accountId, userContext?.boardId, activeTemplateDraft) }}>Send me a test of this email</Button>
                                                    <div className="flex flex-row grow-0 items-center pt-2">
                                                        <p className="block text-sm font-medium leading-6 text-gray-900 cursor-pointer mr-1" onClick={() => { setPlaceholdersTipIndex(0); setShowPlaceholdersTip(!showPlaceholdersTip) }}>Placeholders</p>
                                                        <QuestionMarkCircleIcon className="h-5 w-5 cursor-pointer" aria-hidden="true" onClick={() => { setPlaceholdersTipIndex(0); setShowPlaceholdersTip(!showPlaceholdersTip) }} />
                                                        {/* TODO the esc button should set the tip state */}
                                                        {showPlaceholdersTip && <Tipseen
                                                            position={Tipseen.positions.RIGHT}
                                                            closeButtonTheme={Tipseen.closeButtonThemes.LIGHT}
                                                            animationType={Tipseen.animationTypes.EXPAND}
                                                            onClose={() => { setPlaceholdersTipIndex(0); setShowPlaceholdersTip(false); }}

                                                            content={
                                                                <TipseenWizard title="Using Placeholders"
                                                                    steps={[
                                                                        <div key="explainPlaceholder-0"><p>Placeholders are references to columns and attributes in the items, groups and boards you want to send emails about.</p>
                                                                            <p> To make emails dynamic, click the column name to add a placeholder for that column in your email. When the email is sent, the placeholder will be substituted for the real value.</p></div>,
                                                                        <div key="explainPlaceholder-1"><p>Don&apos;t modify any text inside of the <span className="font-mono">&#123;braces&#125;</span>. If the placeholder name doesn&apos;t match a column ID on your board, it wont be substituted and your email won&apos;t look right!</p></div>
                                                                    ]}
                                                                    activeStepIndex={placeholdersTipIndex}
                                                                    onChangeActiveStep={onChangePlaceholderExplainStep} />
                                                            }>
                                                            <div className="monday-storybook-tipseen_big-container" />
                                                        </Tipseen>
                                                        }
                                                    </div>


                                                    <div className="m-2 gap-1 flex flex-row flex-wrap">
                                                        {
                                                            boardColumns.map((column) => {
                                                                return (<Button key={`addToText_${column.id}`} className="grow-0 w-32" kind={Button.kinds.SECONDARY} onClick={() => addAtEditorCursor(`{${column.id}}`)}>{column.title}</Button>);
                                                            })
                                                        }
                                                    </div>

                                                </div>
                                                <div className="col-span-full flex flex-col justify-end">
                                                    <Button color={Button.colors.POSITIVE} disabled={saving || userContext?.userId != activeTemplateDraft.from} onClick={saveTemplate}>
                                                        {userContext?.userId != activeTemplateDraft.from ? "You cannot edit templates you don't own" : "Save"}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }
                        </section>
                    </div>
                </div>
            </div></>;
    };


    return (
        <>
            <Helmet script={[{
                type: "text/javascript",
                innerHTML: `var Tawk_API=Tawk_API||{ }, Tawk_LoadStart=new Date();
                    (function(){var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                    s1.async=true;
                    s1.src='https://embed.tawk.to/65b3f1830ff6374032c54a92/1hl3eeqa6';
                    s1.charset='UTF-8';
                    s1.setAttribute('crossorigin','*');
                    s0.parentNode.insertBefore(s1,s0);
                    })();`
            }]}>
            </Helmet>
            <div className="App h-screen bg-gray-900">
                <div className="h-screen flex flex-col">
                    <Popover as="header" className="">
                        {() => (
                            <>
                                <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                                    <div className="relative flex items-center justify-center py-5 lg:justify-between">
                                        {/* Search */}
                                        {/* <div className="min-w-0 flex-1 px-12 lg:hidden">
                                        <div className="mx-auto w-full max-w-xs">
                                            <label htmlFor="desktop-search" className="sr-only">
                                                Search
                                            </label>
                                            <div className="relative text-white focus-within:text-gray-600">
                                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                    <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                                                </div>
                                                <input
                                                    id="desktop-search"
                                                    className="block w-full rounded-md border-0 bg-white/20 py-1.5 pl-10 pr-3 text-white placeholder:text-white focus:bg-white focus:text-gray-900 focus:ring-0 focus:placeholder:text-gray-500 sm:text-sm sm:leading-6"
                                                    placeholder="Search"
                                                    type="search"
                                                    name="search"
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                    </div>

                                    {/* re-add py-5 to this div to create header space*/}
                                    <div className="hidden lg:block">
                                        <div className="grid grid-cols-3 items-center gap-8">
                                            {/* <div>
                                            <div className="mx-auto w-full max-w-md">
                                                <label htmlFor="mobile-search" className="sr-only">
                                                    Search
                                                </label>
                                                <div className="relative text-white focus-within:text-gray-600">
                                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                        <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                                                    </div>
                                                    <input
                                                        id="mobile-search"
                                                        className="block w-full rounded-md border-0 bg-white/20 py-1.5 pl-10 pr-3 text-white placeholder:text-white focus:bg-white focus:text-gray-900 focus:ring-0 focus:placeholder:text-gray-500 sm:text-sm sm:leading-6"
                                                        placeholder="Search"
                                                        type="search"
                                                        name="search"
                                                    />
                                                </div>
                                            </div>
                                        </div> */}
                                            <div className="col-span-2">
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </>
                        )}
                    </Popover>
                    <main className="grow flex flex-col overflow-auto">
                        <div className="grow flex flex-col mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8 overflow-auto">
                            {userContext && (userContext.isViewOnly || userContext.isGuest ? accessDeniedView(userContext) : emailTemplatesView())}
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}
