import { Fragment, useEffect, useState, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  ArrowLeftCircleIcon,
  Bars3Icon,
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  PaperAirplaneIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { AuthenticationStatus } from '@/api/ApiTypes';
import UserContextProvider from '@/providers/userContextProvider';
import { Link } from 'react-router-dom';
import SendingConfiguration from '@/components/sendingConfiguration';
import Dashboard from '@/components/dashboard';
import { useSearchParams } from 'react-router-dom';
import { useMondaySdk } from '@/hooks/useMondaySdk';
import { getDeclaredDomain } from '@/api/ApiCalls';
import { Button } from 'monday-ui-react-core';

const teams = [
  { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
  { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
  { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Settings() {
  const monday = useMondaySdk();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showDomainAuthenticationBanner, setShowDomainAuthenticationBanner] =
    useState(false);
  const userContext = useContext(UserContextProvider);

  useEffect(() => {
    if (!searchParams.get('section')) {
      setSearchParams({ section: 'dashboard' });
    }
  }, [searchParams]);

  useEffect(() => {
    if (!userContext) return;

    getDeclaredDomain(monday, userContext.accountId).then((res) => {
      setShowDomainAuthenticationBanner(
        res.authenticationStatus !== AuthenticationStatus.VERIFIED
      );
    });
  }, [userContext?.accountId]);

  const navigation = [
    {
      name: 'Dashboard',
      href: '/settings?section=dashboard',
      icon: HomeIcon,
      current: false,
      component: <Dashboard />,
    },
    {
      name: 'Sending Configuration',
      href: '/settings?section=sendingconfiguration',
      icon: PaperAirplaneIcon,
      current: false,
      component: (
        <SendingConfiguration
          onSuccessfulAuthentication={() =>
            setShowDomainAuthenticationBanner(false)
          }
        />
      ),
    },
    // {
    //   name: 'Team',
    //   href: '/settings?section=team',
    //   icon: UsersIcon,
    //   current: false,
    //   component: <SendingConfiguration />,
    // },
    // {
    //   name: 'Calendar',
    //   href: '/settings?section=calendar',
    //   icon: CalendarIcon,
    //   current: false,
    //   component: <SendingConfiguration />,
    // },
    // {
    //   name: 'Documents',
    //   href: '/settings?section=documents',
    //   icon: DocumentDuplicateIcon,
    //   current: false,
    //   component: <SendingConfiguration />,
    // },
    // {
    //   name: 'Reports',
    //   href: '/settings?section=reports',
    //   icon: ChartPieIcon,
    //   current: false,
    //   component: <SendingConfiguration />,
    // },
  ];

  const getComponentOrEmptyView = (section) => {
    const navItem = navigation.filter((item) => item.href.endsWith(section));
    return navItem.length ? navItem[0].component : <></>;
  };

  const domainAuthenticationBanner = () => {
    return (
      <div className="rounded-md bg-yellow-50 p-4 mb-8 w-3/4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              Setup needed!
            </h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>
                Setup is needed to start sending emails! You can do that on the{' '}
                <Link to={'/settings?section=sendingconfiguration'}>
                  <span className="text-blue-500 underline">
                    Sending Configuration
                  </span>
                </Link>{' '}
                page.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <Link to={item.href}>
                                  <p
                                    className={classNames(
                                      item.href.endsWith(
                                        searchParams.get('section')
                                      )
                                        ? 'bg-gray-50 text-blue-600'
                                        : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50',
                                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                    )}
                                  >
                                    <item.icon
                                      className={classNames(
                                        item.href.endsWith(
                                          searchParams.get('section')
                                        )
                                          ? 'text-blue-600'
                                          : 'text-gray-400 group-hover:text-blue-600',
                                        'h-6 w-6 shrink-0'
                                      )}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </p>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          {/* <div className="text-xs font-semibold leading-6 text-gray-400">
                            Your teams
                          </div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {teams.map((team) => (
                              <li key={team.name}>
                                <a
                                  href={team.href}
                                  className={classNames(
                                    team.current
                                      ? 'bg-gray-50 text-indigo-600'
                                      : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <span
                                    className={classNames(
                                      team.current
                                        ? 'text-indigo-600 border-indigo-600'
                                        : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
                                      'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                                    )}
                                  >
                                    {team.initial}
                                  </span>
                                  <span className="truncate">{team.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul> */}
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
            <div className="flex h-16 shrink-0 items-center">
              <Link className="flex flex-row" to={`/`}>
                <Button
                  className="grow flex flex-row mt-2"
                  color={Button.colors.PRIMARY}
                >
                  <ArrowLeftCircleIcon
                    className="-ml-0.5 h-5 w-5 mr-2"
                    aria-hidden="true"
                  />
                  Back to templates
                </Button>
              </Link>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link to={item.href}>
                          <p
                            className={classNames(
                              item.href.endsWith(searchParams.get('section'))
                                ? 'bg-gray-50 text-blue-600'
                                : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.href.endsWith(searchParams.get('section'))
                                  ? 'text-blue-600'
                                  : 'text-gray-400 group-hover:text-blue-600',
                                'h-6 w-6 shrink-0'
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  {/* <div className="text-xs font-semibold leading-6 text-gray-400">
                    Your teams
                  </div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <a
                          href={team.href}
                          className={classNames(
                            team.current
                              ? 'bg-gray-50 text-indigo-600'
                              : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <span
                            className={classNames(
                              team.current
                                ? 'text-indigo-600 border-indigo-600'
                                : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
                              'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                            )}
                          >
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul> */}
                </li>
                {/* <li className="-mx-6 mt-auto">
                  <a
                    href="#"
                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                  >
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                    <span className="sr-only">Your profile</span>
                    <span aria-hidden="true">Tom Cook</span>
                  </a>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
            Dashboard
          </div>
          <Link className="flex flex-row" to={`/`}>
            <button
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <ArrowLeftCircleIcon
                className="-ml-0.5 h-5 w-5"
                aria-hidden="true"
              />
              Back to templates
            </button>
          </Link>
        </div>

        <main className="py-10 lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-8">
            {showDomainAuthenticationBanner &&
              searchParams.get('section') !== 'sendingconfiguration' &&
              domainAuthenticationBanner()}
            {getComponentOrEmptyView(searchParams.get('section'))}
          </div>
        </main>
      </div>
    </>
  );
}
