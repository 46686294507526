export type APIConfiguration = {
  BASE: string;
  VERSION: string;
  WITH_CREDENTIALS: boolean;
  CREDENTIALS: string;
  TOKEN: string | undefined;
  USERNAME: string | undefined;
  PASSWORD: string | undefined;
  HEADERS: string | undefined;
  ENCODE_PATH: string | undefined;
};

export const APIConfig: APIConfiguration = {
  BASE: '',
  VERSION: '1',
  WITH_CREDENTIALS: false,
  CREDENTIALS: 'include',
  TOKEN: undefined,
  USERNAME: undefined,
  PASSWORD: undefined,
  HEADERS: undefined,
  ENCODE_PATH: undefined,
};
