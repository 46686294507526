import { UUID } from "crypto";
import { EmailTemplate } from "@/routes/root";
import { classNames } from "@/lib/utils";

export type EmailTemplateListItemProps = {
    template: EmailTemplate;
    isActive: boolean;
    onSelection: () => void;
};

export default function EmailTemplateListItem(props: EmailTemplateListItemProps) {
    return (
        <div className="grid grid-cols-1 gap-4 cursor-pointer" onClick={() => props.onSelection()}>
            <div
                key={"an email"}
                className={classNames(props.isActive ? "bg-indigo-300" : "bg-white ", "relative flex items-center space-x-3 rounded-lg border border-gray-300 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-900")}
            >
                <div className="flex-shrink-0">
                </div>
                <div className="min-w-0 flex-1">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-sm font-medium text-gray-900">{props.template.name}</p>
                    {/* <p className="truncate text-sm text-gray-500">{"a role"}</p> */}
                </div>
            </div>
        </div>
    );
}