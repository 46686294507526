import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMondaySdk } from '@/hooks/useMondaySdk';
import {
  getDeclaredDomain,
  getDomainAuthenticationStatus,
  mondayGetCurrentUserEmail,
  checkDomainVerification,
} from '@/api/ApiCalls';
import { TextField } from 'monday-ui-react-core';
import {
  InformationCircleIcon,
  ExclamationTriangleIcon,
  ChevronDownIcon,
} from '@heroicons/react/20/solid';
import { ClipboardIcon } from '@heroicons/react/24/outline';
import UserContextProvider from '@/providers/userContextProvider';
import { Button } from 'monday-ui-react-core';

const SendingConfiguration = (props) => {
  const monday = useMondaySdk();
  const userContext = useContext(UserContextProvider);
  const [domainAuthStatus, setDomainAuthStatus] = useState();
  const [domain, setDomain] = useState();
  const [domainHasBeenFetched, setDomainHasBeenFetched] = useState(false);
  const [currentUserDomainHasBeenFetched, setCurrentUserDomainHasBeenFetched] =
    useState(false);
  const [domainInput, setDomainInput] = useState(null);
  const [loading, setLoading] = useState(false);
  const [verifyingDns, setVerifyingDns] = useState(false);
  const [dnsVerificationResult, setDnsVerificationResult] = useState(false);
  const [copiedFields, setCopiedFields] = useState({});

  useEffect(() => {
    setLoading(true);
    if (userContext === undefined) return;

    mondayGetCurrentUserEmail(monday).then((email) => {
      setDomainInput(email.split('@')[1]);
      setCurrentUserDomainHasBeenFetched(true);
    });
    getDeclaredDomain(monday, userContext.accountId).then((res) => {
      setDomain(res.domain);
      setDomainHasBeenFetched(true);
    });
  }, [userContext?.accountId]);

  useEffect(() => {
    if (!domainHasBeenFetched || !currentUserDomainHasBeenFetched) {
      return;
    }

    if (!domain) {
      setLoading(false);
      return;
    }

    getDomainAuthenticationStatus(monday, userContext.accountId, domain).then(
      (status) => {
        setDomainAuthStatus(status);
        setLoading(false);
      }
    );
  }, [domain, domainInput]);

  const submitDomain = () => {
    setLoading(true);
    getDomainAuthenticationStatus(
      monday,
      userContext.accountId,
      domainInput
    ).then((status) => {
      setLoading(false);
      setDomain(domainInput);
      setDomainAuthStatus(status);
    });
  };

  const verifyDnsEntries = () => {
    setVerifyingDns(true);
    checkDomainVerification(monday, userContext.accountId).then((res) => {
      setVerifyingDns(false);
      setDnsVerificationResult(res);
      if (res.valid) {
        props.onSuccessfulAuthentication();
      }
    });
  };

  const getMailServerCnameMailServerValue = (name) => {
    if (name.split('.')[0] === 'ew514') {
      return 'ew514';
    }
    return name;
  };

  const dmarcExplanation = (prelimMessage, dmarcDnsRecord) => {
    return (
      <>
        <p>
          {prelimMessage && prelimMessage + ' '}I recommend you use this{' '}
          <a href="https://dmarc.postmarkapp.com/" className="text-blue-500">
            free service
          </a>{' '}
          to guide you through creating a DMARC record. Doing so gives you a
          nice weekly report and avoids daily reports from mail providers.
        </p>
        <div className="m-2 space-y-2">
          <div
            className="group flex flex-col rounded-lg bg-gray-100"
            tabIndex="1"
          >
            <div className="flex cursor-pointer items-center justify-between">
              <span className="p-2">
                Don&apos;t want to use a 3rd party service? Here is a record you
                can use:
              </span>
              {/* <img
                src="https://upload.wikimedia.org/wikipedia/commons/9/96/Chevron-icon-drop-down-menu-WHITE.png"
                className="h-2 w-3 transition-all duration-500 group-focus:-rotate-180"
              /> */}
              <ChevronDownIcon
                className="h-5 w-5 text-black transition-all duration-500 group-focus:-rotate-180"
                aria-hidden="true"
              />
            </div>
            <div className="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
              <div className="p-2">
                <p>
                  Add this{' '}
                  <strong className="font-code">
                    {dmarcDnsRecord.type.toUpperCase()}
                  </strong>{' '}
                  record to your domain&apos;s DNS at:{' '}
                  <strong className="font-code bold">
                    {getMailServerCnameMailServerValue(dmarcDnsRecord.host)}
                  </strong>{' '}
                  with the following data:
                  <br />
                </p>
                <div
                  className="bg-black cursor-pointer p-3 pr-5 inline-block flex flex-row break-all items-center w-1/2"
                  onClick={() => {
                    navigator.clipboard.writeText(dmarcDnsRecord.data);
                    const newFields = { ...copiedFields };
                    newFields['DMARC'] = true;
                    setCopiedFields(newFields);
                    setTimeout(() => {
                      const newFields = { ...copiedFields };
                      newFields['DMARC'] = false;
                      setCopiedFields(newFields);
                    }, 1500);
                  }}
                >
                  <ClipboardIcon
                    className="h-5 w-5 text-indigo-300 shrink-0"
                    aria-hidden="true"
                  />
                  <p className="pl-3 font-code text-gray-300">
                    {dmarcDnsRecord.data}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const dmarcExplanationListItem = (dmarcDnsRecord) => {
    return (
      <li className="ml-8">
        {dmarcExplanation('A DMARC record.', dmarcDnsRecord)}
      </li>
    );
  };

  const verificationInProgress = () => {
    return (
      <>
        {loading && <p>Checking the status of your domain...</p>}
        {!domain &&
          domainHasBeenFetched &&
          currentUserDomainHasBeenFetched &&
          !loading && (
            <main>
              <p className="pb-4">
                In order to send emails that come from your domain, you need to
                add DNS entries.{' '}
                <strong>(Don&apos;t worry, it&apos;s easy!)</strong>
              </p>
              <p>To start, what site do your emails come from?</p>
              <div className="w-80 pb-2 pt-2">
                <TextField
                  value={domainInput}
                  onChange={setDomainInput}
                  autoComplete="off"
                  data-form-type="other"
                />
              </div>
              <Button
                onClick={() => submitDomain()}
                className="grow flex flex-row mt-2"
                color={Button.colors.PRIMARY}
              >
                Let&apos;s go!
              </Button>
            </main>
          )}

        {domain && domainAuthStatus && !domainAuthStatus.valid && (
          <>
            <p className="pb-2">
              In order to send emails that come from your domain, you need to
              add a couple of DNS entries.{' '}
              <strong>(Don&apos;t worry, it&apos;s easy!)</strong>
            </p>
            <h3 className="text-lg font-bold text-gray-900 sm:text-lg pb-2 pt-2">
              How to add DNS records:
            </h3>
            <p className="pb-4">
              You can watch{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.loom.com/share/8e5cfdab2c68470d938bc8477cd8ff33"
                className="underline text-indigo-400"
              >
                this explainer video
              </a>{' '}
              to see how add the required entries to your domain, or follow the
              written steps below.
              <ol className="list-decimal">
                <li className="ml-8">Log into your domain provider.</li>
                <li className="ml-8">
                  There will be a list or portfolio of the domains you currently
                  own. Find <span className="underline">{domain}</span> in that
                  list and click on it to administrate it.
                </li>
                <li className="ml-8">
                  There should be submenu titled <strong>DNS</strong>. Click
                  that.
                  <br />
                  If there isn&apos;t there may be a table present on that page
                  that looks like this. Scroll to that table.
                </li>
                <li className="ml-8">
                  You will see a table of records that your domain already has.
                </li>
              </ol>
            </p>

            <h3 className="text-lg font-bold text-gray-900 sm:text-lg pb-2 pt-2">
              The required DNS records:
            </h3>
            <ul className="list-disc">
              {Object.keys(domainAuthStatus.dns)
                .filter((key) => key !== 'DMARC')
                .map((key) => (
                  <li key={domainAuthStatus.dns[key].host} className="ml-8">
                    Add this{' '}
                    <strong className="font-code">
                      {domainAuthStatus.dns[key].type.toUpperCase()}
                    </strong>{' '}
                    record to your domain&apos;s DNS at:{' '}
                    <strong className="font-code bold">
                      {getMailServerCnameMailServerValue(
                        domainAuthStatus.dns[key].host
                      )}
                    </strong>{' '}
                    with the following data:
                    <br />
                    <div className="flex flex-row items-center pb-3">
                      <div
                        className="bg-black cursor-pointer p-3 pr-5 inline-block flex flex-row break-all items-center w-1/2"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            domainAuthStatus.dns[key].data
                          );
                          const newFields = { ...copiedFields };
                          newFields[key] = true;
                          setCopiedFields(newFields);
                          setTimeout(() => {
                            const newFields = { ...copiedFields };
                            newFields[key] = false;
                            setCopiedFields(newFields);
                          }, 1500);
                        }}
                      >
                        <ClipboardIcon
                          className="h-5 w-5 text-indigo-300 shrink-0"
                          aria-hidden="true"
                        />
                        <p className="pl-3 font-code text-gray-300">
                          {domainAuthStatus.dns[key].data}
                        </p>
                      </div>
                      {copiedFields && copiedFields[key] && (
                        <p className="pl-3">copied!</p>
                      )}
                    </div>
                  </li>
                ))}
              {domainAuthStatus.dns &&
                domainAuthStatus.dns['DMARC'] &&
                dmarcExplanationListItem(domainAuthStatus.dns['DMARC'])}
            </ul>
            {verifyingDns && (
              <p className="p-5">Verifying DNS configuration...</p>
            )}
            {!verifyingDns && dnsVerificationResult && (
              <>
                <ul className="pt-4 list-disc">
                  {Object.keys(dnsVerificationResult.validationResults).map(
                    (key) => (
                      <li
                        key={dnsVerificationResult.validationResults[key].host}
                        className="ml-8"
                      >
                        {dnsVerificationResult.validationResults[key].valid
                          ? `✅ ${key} is correct!`
                          : key == 'DMARC'
                          ? '⚠️ DMARC record is not found. This is optional, but recommended.'
                          : `❌ ${key} is incorrect or not found`}
                      </li>
                    )
                  )}
                </ul>
                <div className="ml-4 pt-2 pb-6 w-3/4">
                  <div className="rounded-md bg-blue-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <InformationCircleIcon
                          className="h-5 w-5 text-blue-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 flex-1 md:flex md:justify-between">
                        <p className="text-sm text-blue-700">
                          It can sometimes take minutes for DNS changes like
                          this to become visible to the internet. <br />
                          If you&apos;ve set the values but they still show as
                          not correct, wait 60 seconds and recheck them again.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="">
              {!verifyingDns && (
                <>
                  <div className="pb-10">
                    <Button
                      disabled={verifyingDns}
                      onClick={() => verifyDnsEntries()}
                      className="grow flex flex-row mt-2"
                      color={Button.colors.PRIMARY}
                    >
                      {dnsVerificationResult
                        ? 'Recheck my records!'
                        : 'Done! Check them out!'}
                    </Button>
                  </div>
                  {havingTroubleCta()}
                </>
              )}
            </div>
          </>
        )}
      </>
    );
  };

  const verificationComplete = () => {
    return (
      <>
        <h2 className="text-2xl font-bold text-gray-900 sm:text-2xl pb-5">
          You&apos;re ready to start sending emails! 😎
        </h2>
        <ul className="list-disc">
          {Object.keys(domainAuthStatus.dns)
            .filter((key) => domainAuthStatus.dns[key].valid)
            .map((key) => (
              <li key={domainAuthStatus.dns[key].host} className="ml-5">
                <p>
                  ✅{' '}
                  <strong className="font-mono">
                    {domainAuthStatus.dns[key].type.toUpperCase()}
                  </strong>{' '}
                  {' record found! Name: '}
                  <strong className="font-mono">
                    {getMailServerCnameMailServerValue(
                      domainAuthStatus.dns[key].host
                    )}
                  </strong>
                  {' and data: '}
                  <strong className="font-mono">
                    {domainAuthStatus.dns[key].data}
                  </strong>
                </p>
              </li>
            ))}
          {Object.keys(domainAuthStatus.dns)
            .filter(
              (key) => !domainAuthStatus.dns[key].valid && key !== 'DMARC'
            )
            .map((key) => (
              <li key={domainAuthStatus.dns[key].host} className="ml-5">
                <p>
                  ✅{' '}
                  <strong className="font-mono">
                    {domainAuthStatus.dns[key].type.toUpperCase()}
                  </strong>{' '}
                  {' record found! Name: '}
                  <strong className="font-mono">
                    {getMailServerCnameMailServerValue(
                      domainAuthStatus.dns[key].host
                    )}
                  </strong>
                  {' and data: '}
                  <strong className="font-mono">
                    {domainAuthStatus.dns[key].data}
                  </strong>
                </p>
              </li>
            ))}
          {Object.keys(domainAuthStatus.dns)
            .filter((key) => !domainAuthStatus.dns[key].valid && key == 'DMARC')
            .map((key) => (
              <li key={domainAuthStatus.dns[key].host} className="ml-5">
                <p>
                  ⚠️ You are missing a DMARC record. This is optional, but
                  recommended.
                </p>
                {dmarcExplanation('', domainAuthStatus.dns[key])}
              </li>
            ))}
        </ul>
      </>
    );
  };

  const havingTroubleCta = () => {
    return (
      <div className="rounded-md bg-yellow-50 p-4 w-3/4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              Having trouble?
            </h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.loom.com/share/8e5cfdab2c68470d938bc8477cd8ff33"
                  className="underline text-indigo-400"
                >
                  This video
                </a>{' '}
                is an example of a step-by-step setup.
                <br />
                Use the chat widget in the bottom right corner of this page to
                talk directly with me, or feel free to email me:{' '}
                <a
                  href="mailto:matt@emailwonderful.com"
                  className="underline text-indigo-400"
                >
                  matt@emailwonderful.com
                </a>{' '}
                and I&lsquo;ll help set you up!
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="">
      <h2 className="text-5xl font-bold text-gray-900 sm:text-5xl pb-5">
        Sending Configuration
      </h2>
      {domain && (domainAuthStatus?.valid || dnsVerificationResult?.valid)
        ? verificationComplete()
        : verificationInProgress()}
    </div>
  );
};

SendingConfiguration.propTypes = {
  onSuccessfulAuthentication: PropTypes.func,
};

export default SendingConfiguration;
