import { APIConfig } from '@/api/ApiConfig';
import { MondayClientSdk } from 'monday-sdk-js/types/client-sdk.interface';
import {
  AuthenticationStatus,
  DeclaredDomain,
  DomainAuthenticationStatusResponse,
  DomainValidationResponse,
  MessageResponse,
  MondayCurrentUser,
  MondayUser,
  Usage,
} from './ApiTypes';
import { EmailTemplate } from '@/routes/root';

export const getDeclaredDomain = async (
  monday: MondayClientSdk,
  accountId: string
): Promise<DeclaredDomain> => {
  return fetch(
    `${APIConfig.BASE}/account/${accountId}/domainauthentication/domain`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: (await monday.get('sessionToken')).data,
      },
    }
  )
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      if (response.status === 404) {
        return Promise.resolve({
          domain: null,
          authenticationStatus: AuthenticationStatus.PENDING,
        });
      }
      monday.execute('notice', {
        message: 'Error getting declared domain! Please contact support.',
        type: 'error',
        timeout: 1500,
      });
      throw new Error('Error getting declared domain ' + response.status);
    })
    .then((data: DeclaredDomain) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
      return { domain: '', authenticationStatus: AuthenticationStatus.PENDING };
    });
};

export const getDomainAuthenticationStatus = async (
  monday: MondayClientSdk,
  accountId: string,
  domain: string
): Promise<DomainAuthenticationStatusResponse> => {
  return fetch(
    `${APIConfig.BASE}/account/${accountId}/domainauthentication/domain/authentication`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: (await monday.get('sessionToken')).data,
      },
      body: JSON.stringify({ domain: domain }),
    }
  )
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      monday.execute('notice', {
        message:
          'Error checking status of declared domain. Please contact support.',
        type: 'error',
        timeout: 1500,
      });
      throw new Error(
        'Error checking status of declared domain ' + response.status
      );
    })
    .then((data: DomainAuthenticationStatusResponse) => {
      return data;
    });
};

export const checkDomainVerification = async (
  monday: MondayClientSdk,
  accountId: string
): Promise<DomainValidationResponse> => {
  return fetch(
    `${APIConfig.BASE}/account/${accountId}/domainauthentication/domain/authentication`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: (await monday.get('sessionToken')).data,
      },
    }
  )
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      monday.execute('notice', {
        message:
          'Error checking status of declared domain. Please contact support.',
        type: 'error',
        timeout: 1500,
      });
      throw new Error(
        'Error checking status of declared domain ' + response.status
      );
    })
    .then((data: DomainValidationResponse) => {
      return data;
    });
};

export const mondayGetCurrentUserEmail = async (
  monday: MondayClientSdk
): Promise<string> => {
  return monday
    .api('query {me {email}}')
    .then((reponseBody: any) => {
      const data = reponseBody.data as MondayCurrentUser;
      return data.me;
    })
    .then((user: MondayUser) => {
      return user.email;
    });
};

export const sendTestEmail = async (
  accountId: string | undefined,
  boardId: number | undefined,
  activeTemplateDraft: EmailTemplate,
  toAddress: string,
  monday: MondayClientSdk
): Promise<MessageResponse> => {
  return fetch(`${APIConfig.BASE}/account/${accountId}/board/${boardId}/test`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: (await monday.get('sessionToken')).data,
    },
    body: JSON.stringify({ ...activeTemplateDraft, toAddress: toAddress }),
  }).then((response) => {
    if (response.status === 200) {
      return Promise.resolve({
        isError: false,
        message: 'Email sent! Check your inbox',
      });
    }

    return Promise.resolve({
      isError: false,
      message: 'Error sending a test email! Please contact support',
    });
  });
};

export const getPlanUsage = async (
  accountId: string | undefined,
  monday: MondayClientSdk
): Promise<Usage> => {
  return fetch(`${APIConfig.BASE}/account/${accountId}/usage`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: (await monday.get('sessionToken')).data,
    },
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    throw new Error('Error sending test email ' + response.status);
  });
};