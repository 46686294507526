import { getPlanUsage } from "@/api/ApiCalls";
import { Usage } from "@/api/ApiTypes";
import { useMondaySdk } from "@/hooks/useMondaySdk";
import UserContextProvider from "@/providers/userContextProvider";
import moment from "moment";
import { useContext, useEffect, useState } from "react";


const Dashboard = (props) => {
    const userContext = useContext(UserContextProvider);
    const [usage, setUsage] = useState<Usage>();
    const [loading, setLoading] = useState(true);
    const monday = useMondaySdk();

    useEffect(() => {
        if (!userContext) return;

        getPlanUsage(userContext.accountId, monday).then(usage => {
            setUsage(usage);
            setLoading(false);
        });
    }, [userContext?.accountId]);

    return (
        <div>
            <h2 className="text-5xl font-bold text-gray-900 sm:text-5xl pb-5">Dashboard</h2>
            {loading ? <p>Fetching your account details...</p> : <>
                <p>You are currently on the <strong>{usage?.plan}</strong> plan</p>
                <p>This comes with <strong>{usage?.quota}</strong> emails per month</p>
                <p>So far this month, your team has sent <strong>{usage?.used}</strong> emails</p>
                <p>Your renewal date is <strong>{moment(usage?.renewalDate).format("ll")}</strong></p>
            </>}
        </div>
    );
};

Dashboard.propTypes = {

};

export default Dashboard;
